
*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  font-family: "Geist";
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
svg {
  vertical-align: middle;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("HelveticaNeue"), url("../assets/fonts/HelveticaNeue/HelveticaNeue.ttf") format("ttf");
}

@font-face {
  font-family: "Beatrice";
  font-weight: 400;
  src: local("BeatriceRegular"),
    url("../assets/fonts/Beatrice/Beatrice-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Beatrice";
  font-weight: 600;
  src: local("BeatriceSemibold"),
    url("../assets/fonts/Beatrice/Beatrice-Semibold.woff2") format("woff2");
}

@font-face {
  font-family: "Geist";
  src: url("../assets/fonts/Geist/Geist-VariableFont.woff2") format("woff2");
}
@font-face {
  font-family: "GeistMono";
  src: url("../assets/fonts/Geist/GeistMono-VariableFont.woff2") format("woff2");
}

code {
  font-family: GeistMono, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  background-color: currentColor;
}
