.legend {
  margin-top: 1.75rem;
  color: #686764;
  
  ul {
    display: flex;
    padding-left: 0;
    li {
      height: 26px;
      font-size: 0.75rem;
      padding-left: 1.25rem;
      list-style: none;
      line-height: 1.25rem;
      letter-spacing: 0.75%;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}
